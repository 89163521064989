<template>
  <ion-page><cyan-page-wrapper :title="title" :backRoute="backRoute" nav1="MODO PARTIDO">
    <territory-picker nextRoute="/partyList/" :seedTP="seed" />
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store
} from "@/components/CyanPageWrapper.vue";
import TerritoryPicker from "@/components/TerritoryPicker.vue";
import { territorios } from '@/modules/cyanRegions'
import unreact from "@/modules/unreact";

export default defineComponent({
  name: "PickMpio",
  components: {
    TerritoryPicker,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    seed() {
      return unreact((this as any).$route.params.seedPM+'') || 'SV'
    }, 

    title() {
			if (this.seed in territorios) {
				return 'Elegir municipio (' + territorios[(this as any).seed].nombre + ')';
			}
			return 'Elegir municipio';
		},
		userLevel() {
      return store.state.userData.nivel || '';
    },
		backRoute() {
			return ((this as any).userLevel != 'SV') ? '/partyLevels' : '/pickDeptoMpio'
		},

  },
  methods: {},
});
</script>
